
require('normalize.css/normalize.css');
require('./styles/index.scss');





document.addEventListener("DOMContentLoaded", () => {
 fullscreenImage();


window.addEventListener("resize",fullscreenImage)
window.addEventListener("scroll",headerOnscroll)


let video = document.getElementById("bgvid");
video.onloadeddata = function() {
 fullscreenImage();
};

});


function fullscreenImage() {
 let homeScreen = document.querySelector(".home");
 let video = document.getElementById("bgvid");
 homeScreen.style.height = video.clientHeight+'px';
}

const headerOnscroll = (...args)=>{
 let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
 let header = document.querySelector(".header");
 if(scrollTop>100) header.classList.add("scrolled");
 else header.classList.remove("scrolled");
 
 
}